import { motion } from 'framer-motion';
import { useMemo } from 'react';
import animations from 'utils/animations';

export default function Media({ source, title, controls }) {
  const { opacity } = animations.components;
  const { fileTypes } = require('configs/settings.json');

  const fileType = useMemo(() => {
    if (source) {
      const fileExtension = source.split('.').pop();

      if (fileTypes.image.includes(fileExtension)) {
        return 'image';
      } else if (fileTypes.video.includes(fileExtension)) {
        return 'video';
      }
  
      return '';
    } else {
      return '';
    }
  }, [source, fileTypes]);

  return (
    <>
      {fileType === 'image' && (
        <motion.img 
          {...opacity}
          transition={{ delay: .2 }}
          src={source} 
          alt=""
          className="w-full" />
      )}
      {fileType === 'video' && (
        <motion.video 
          {...opacity}
          transition={{ delay: .3 }}
          autoPlay={!controls} 
          playsInline 
          muted={!controls} 
          loop 
          controls={controls} 
          className="w-full"
          aria-hidden='true'>
          <source src={source} type="video/mp4" />
          <track src="video/video.srt" kind="subtitles" srcLang="en" label="English" />
        </motion.video>
      )}
    </>
  );
}
