import { useEffect } from 'react';
import { 
  Outlet, 
  ScrollRestoration, 
  useMatch, 
  useNavigation } from 'react-router-dom';
import useVideoStore from 'stores/videoStore';
import Nav from 'components/Nav';
import Hero from 'components/Hero';
import Footer from 'components/Footer';
import LoadingScreen from 'components/LoadingScreen';

export default function Layout() {
  const navigation = useNavigation();
  const isHome = useMatch('/');
  const videoHeader = useVideoStore((state) => state.videoLoaded);

  useEffect(() => {
    /**
     * set dark mode as the default mode
     */
    const root = document.getElementsByTagName('html')[0];
    root.classList.add('dark');
  }, []);

  return (
    <>
      {(navigation.state === 'loading' && !videoHeader)
        ? <LoadingScreen />
        : (
          <>
            <header {...(isHome && {className: 'h-dvh'})}>
              {isHome && (<Hero />)}
              <Nav isHome={isHome} />
            </header>
            <main>
              <Outlet />
            </main>
            <Footer />
            <ScrollRestoration />
          </>
        )}
    </>
  );
}
