import React from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import { motion } from 'framer-motion';
import Seo from 'components/Seo';
import Section from 'components/Section';
import AnimationPage from 'components/AnimationPage';
import arrow from 'assets/images/icon-arrow-back.svg';
import arrowDown from 'assets/images/icon-arrow-down-circle.svg';
import arrowDownHover from 'assets/images/icon-arrow-down-circle-hover.svg';
import animations from 'utils/animations';

export default function CaseStudy() {
  const { 
    seo, 
    hero, 
    tags, 
    client, 
    title, 
    overview, 
    new: news, 
    challenge, 
    approach, 
    feature, 
    recognitions } = useLoaderData().work;
  const next = useLoaderData().next;

  const { opacity, opacityAndMove } = animations.components;
  const [isHovered, setIsHovered] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <AnimationPage>
      <Seo
        title={`${seo?.title + ' - ' || ''}Work - Kollectiff`}
        description={seo?.description}
        image={seo?.image || `${hero.image}?h=630&w=1200`} />
      <div className="case-hero" style={{ backgroundImage: `url(${hero.image})` }}>
        <div className="container px-8 flex flex-col h-full">
          <div className="py-8 flex justify-start">
            <Link to="/work" className="arrow-link">
              <img src={arrow} alt="" /> back to works
            </Link>
          </div>
          <div className="grow grid grid-cols-1 lg:grid-cols-12">
            <div className="flex flex-col justify-center gap-6 lg:col-start-2 lg:col-span-9">
              <motion.div 
                {...opacityAndMove}
                transition={{ delay: .2 }}
                className="text-xs uppercase dark:text-white font-manrope">
                {tags.map(tag => <span key={tag} className="tag">{tag}</span>)}
              </motion.div>
              <div>
                <motion.span
                  {...opacity}
                  transition={{ delay: .3 }}
                  className="uppercase dark:text-white text-xl lg:text-2xl font-light">
                    {client}
                </motion.span>
                <motion.h1 
                  {...opacityAndMove}
                  transition={{ delay: .4 }}
                  className="text-[32px] leading-10 lg:text-6xl lg:leading-[72px] font-bold">
                    {title}
                </motion.h1>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center py-8">
            <motion.a
              href="#overview"
              {...opacityAndMove}
              transition={{ delay: 0.3 }}
              aria-label="Scroll Down"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className='arrow-down'
            >
              <img
                src={isHovered || isFocused ? arrowDownHover : arrowDown}
                alt=""
                className="size-8 lg:size-11"
              />
            </motion.a>
          </div>
        </div>
      </div>
      <Section
        name="overview"
        id="overview"
        customClass="scroll-mt-[104px]"
        title={overview.title}
        description={overview.description}
        media={overview.media}>
        <motion.div 
          {...opacity}
          transition={{ delay: .3 }}
          className="dark:text-white">
          <h3 className="font-bold">Project</h3>
          <p>{overview.project}</p>
        </motion.div>
        <motion.div 
          {...opacity}
          transition={{ delay: .3 }}
          className="dark:text-white">
          <h3 className="font-bold">Services</h3>
          <p>{overview.services}</p>
        </motion.div>
      </Section>
      {news && (
        <div className="container px-8 pt-16">
          <div className="grid grid-cols-1 lg:grid-cols-12">
            <div className="lg:col-span-10 lg:col-start-2 p-6 lg:p-24 flex flex-col items-start gap-4 lg:gap-8" style={{ backgroundColor: news.backgroundColor }}>
              <h2 className="text-2xl lg:text-[64px] leading-tight">{news.title}</h2>
              <a href={news.link} rel="noreferrer" target="_blank" className="dark:text-white text-2xl lg:text-5xl font-bold underline">- {news.source}</a>
            </div>
          </div>
        </div>
      )}
      <Section
        name="challenges"
        title={challenge.title}
        description={challenge.description}
        media={challenge.media} />
      {approach.title && (
        <Section
          name="approach"
          title={approach.title}
          description={approach.description}
          media={approach.media} />
        )}
      {feature.title && (
        <Section
          name="features"
          title={feature.title}
          description={feature.description}
          media={feature.media} />
        )}
      {recognitions && (
        <div className="container px-8 pt-20 pb-[176px]">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
            <div className="lg:col-start-2 lg:col-span-10">
              <h4 className="uppercase font-monument-grotesk-mono text-base text-kollectiff-lime">recognitions</h4>
            </div>
            <div className="lg:col-start-2 lg:col-span-10 grid grid-cols-1 lg:grid-cols-2 gap-8">
              {recognitions.map(recognition =>
                <div className="border border-gray-500 p-6 lg:py-6 lg:px-8 flex flex-row gap-6 lg:gap-8 items-center" key={recognition.title}>
                  <img src={recognition.logo} alt={recognition.title} className="max-w-[60px] w-full lg:w-auto max-h-[60px]" />
                  <div className="dark:text-white text-base lg:text-xl flex flex-col">
                    <a href={recognition.link} target="_blank" rel="noreferrer" className="font-bold underline">{recognition.title}</a>
                    <span>{recognition.description}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="container p-8 flex">
        <Link to="/work" className="arrow-link mr-auto border-b border-kollectiff-lime">
          <img src={arrow} alt="" /> back to works
        </Link>
        {next && (
          <Link to={'/work/' + next} className="arrow-link border-b border-kollectiff-lime">
            next project <img src={arrow} alt="" className="rotate-180" />
          </Link>
        )}
      </div>
    </AnimationPage>
  );
}
