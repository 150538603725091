// import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { PortableText } from '@portabletext/react';
import classNames from 'classnames';
import Media from 'components/Media';
import 'assets/styles/WorkPreview.css';

export default function WorkPreview({ media, tags, title, description, link, section }) {
  const isHomePage = window.location.pathname === "/"; // Check if the current page is the home page
  const HeadingTag = isHomePage ? motion.h3 : motion.h2; // Choose the tag based on the page

  return (
    <div className={classNames('work-preview', { 'works': section })}>
      <div>
        <Media source={media} />
      </div>
      <div className="flex flex-col gap-3">
        <div>
          {tags.map(tag => <span key={tag} className="tag">{tag}</span>)}
        </div>
        <div className="flex flex-col gap-1">
          <HeadingTag
            initial={{ opacity: 0, y: -10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            {title}
          </HeadingTag>
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: .3 }}>
            <PortableText value={description} />
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: .4 }}>
          <Link to={`/work/${link}`} className="read-more text-base">
            Read more
            <span className='sr-only'>about {title}</span>
          </Link>
        </motion.div>
      </div>
    </div >
  );
}
