import { useRef, Fragment } from 'react';
import { Dialog, DialogPanel, Transition } from '@headlessui/react';
import { ReactComponent as CloseIcon } from 'assets/images/icon-close-white.svg';

export default function Modal({ isOpen, onClose, video }) {
  const videoRef = useRef(null);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={videoRef}
        onClose={onClose}
        className="fixed inset-0 z-40 flex items-center justify-center"
        aria-label="Video Modal"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-80" aria-hidden="true" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <DialogPanel
            className="relative w-full max-w-[90vw] sm:max-w-[640px] md:max-w-[1024px] lg:max-w-[1280px] aspect-video shadow-2xl overflow-hidden transform transition-all flex flex-col"
          >
            <button 
              type="button" 
              className="ml-auto p-2 transition-transform hover:rotate-90 close-button"
              onClick={onClose}>
              <CloseIcon className="size-6 stroke-white" />
            </button>
            <video
              ref={videoRef}
              className="w-full h-full"
              autoPlay
              loop
              controls
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </DialogPanel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
