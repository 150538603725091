import Joi from 'joi';
import { AnimatePresence, motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import animations from 'utils/animations';
import emailjs from '@emailjs/browser';
import Seo from 'components/Seo';
import AnimationPage from 'components/AnimationPage';
import CityCard from 'components/CityCard';
import ErrorMessage from 'components/ErrorMessage';
import { ReactComponent as Spinner } from 'assets/images/spinner.svg';
import { useState } from 'react';

export default function Contact() {
  const { cities } = require('data/contact.json');
  const { opacity } = animations.components;
  const [status, setStatus] = useState('idle');

  const schema = Joi.object({
    subject: Joi.string().required(),
    email: Joi.string().email({ tlds: false }).required(),
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    message: Joi.string().required(),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(schema)
  });

  const onSubmit = data => {
    setStatus('loading')

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      data, {
      publicKey: process.env.REACT_APP_EMAILJS_API_KEY
    }
    ).then(
      (response) => {
        setStatus('success');
      },
      (err) => {
        console.error(err)
      }
    )
  }

  return (
    <AnimationPage>
      <Seo title='Contact - Kollectiff' />
      <div className="contact">
        <div className="pt-8 pb-8 lg:py-16 max-w-6xl">
          <motion.h1
            {...opacity}
            transition={{ delay: .2 }}>Ready to build, scope or prototype? Get in touch!</motion.h1>
        </div>
        <div className="lg:w-10/12 pb-16">
          <AnimatePresence mode="wait">
            {status !== 'success' && (
              <motion.form 
                key="form"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onSubmit={handleSubmit(onSubmit)}>
                <motion.div
                  {...opacity}
                  className="form-group">
                  <label htmlFor="subject">subject*</label>
                  <select id="subject" {...register('subject')}>
                    <option value="">Select an option</option>
                    <option value="brand">Brand</option>
                    <option value="agency">Agency</option>
                    <option value="press">Press</option>
                    <option value="careers">Careers</option>
                  </select>
                  <ErrorMessage message="Subject is required" error={errors.subject?.message} />
                </motion.div>
                <motion.div
                  {...opacity}
                  transition={{ delay: .2 }}
                  className="form-group">
                  <label htmlFor="email">email*</label>
                  <input type="email" id="email" name="email" placeholder="Enter your email" {...register('email')} />
                  <ErrorMessage message="Email is required" error={errors.email?.message} />
                </motion.div>
                <motion.div
                  {...opacity}
                  transition={{ delay: .3 }}
                  className="form-group">
                  <label htmlFor="firstName">first name*</label>
                  <input type="text" id="firstName" name="firstName" placeholder="Enter your First Name" {...register('firstName')} />
                  <ErrorMessage message="First name is required" error={errors.firstName?.message} />
                </motion.div>
                <motion.div
                  {...opacity}
                  transition={{ delay: .4 }}
                  className="form-group">
                  <label htmlFor="lastName">last name*</label>
                  <input type="text" id="lastName" name="lastName" placeholder="Enter your Last Name" {...register('lastName')} />
                  <ErrorMessage message="Last name is required" error={errors.lastName?.message} />
                </motion.div>
                <motion.div
                  {...opacity}
                  transition={{ delay: .5 }}
                  className="form-group col-span-2">
                  <label htmlFor="message">message*</label>
                  <textarea id="message" name="message" placeholder="We're excited to hear from you" {...register('message')} />
                  <ErrorMessage message="Message is required" error={errors.message?.message} />
                </motion.div>
                <motion.div
                  {...opacity}
                  transition={{ delay: .6 }}
                  className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0 col-span-2 pt-4">
                  <motion.button
                    type="submit"
                    whileTap={{ scale: 0.8 }}
                    disabled={status === 'loading'}>
                    <AnimatePresence mode="wait">
                      {status === 'idle' && (
                        <motion.span
                          key="submit"
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 20 }}>
                          Get in touch</motion.span>
                      )}
                      {status === 'loading' && (
                        <motion.span
                          key="spinner"
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}>
                          <Spinner />
                        </motion.span>
                      )}
                    </AnimatePresence>
                  </motion.button>
                  <div className="flex lg:flex-col justify-center items-end dark:text-white text-lg gap-1">
                    <span className="font-light">Or email us at</span>
                    <a href="mailto:hello@kollectiff.com" className="font-bold">hello@kollectiff.com</a>
                  </div>
                </motion.div>
              </motion.form>
            )}
            {status === 'success' && (
              <motion.div 
                key="message"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="flex flex-col gap-6">
                <span className="text-kollectiff-lime text-[40px] font-semibold">Thanks for contacting us!</span>
                <span className="dark:text-white text-xl">We have received your message. We’ll reach you out immediately!</span>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="pt-16 pb-[120px] flex flex-col gap-8">
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: .2 }}>We're designed to seamlessly operate<br /> across cultures, timezones, and languages.</motion.h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {cities.map(city =>
              <CityCard data={city} key={city.country} />
            )}
          </div>
        </div>
      </div>
    </AnimationPage>
  );
}
