import { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import AnimationPage from 'components/AnimationPage';
import WorkPreview from 'components/WorkPreview';
// import Filter from 'components/Filter';
import Seo from 'components/Seo';

export default function Work() {
  const works = useLoaderData();
  const [data, setData] = useState(works);
  const tags = [...new Set(works.flatMap(work => work.tags))];
  const [selectedTags, setSelectedTags] = useState(new Set());

  const filterWorks = (tag) => {
    if (selectedTags.has(tag)) {
      setSelectedTags(prev => {
        const newSet = new Set(prev);
        newSet.delete(tag)
        return newSet;
      });
    } else {
      setSelectedTags(prev => {
        const newSet = new Set(prev);
        newSet.add(tag)
        return newSet;
      });
    }
  };

  useEffect(() => {
    if (selectedTags.size > 0) {
      const tags = Array.from(selectedTags);
      const filteredData = data.filter(work => 
        tags.every(tag => work.tags.includes(tag))
      );
      setData(filteredData);
    } else {
      setData(works);
    }
  }, [selectedTags]);

  return (
    <AnimationPage>
      <Seo title='Work - Kollectiff' />
      <div className="container mt-[104px] px-8">
        <div className="py-10 lg:py-16">
          <h1>A selection of creative work,<br /> at the speed of emerging tech</h1>
        </div>
        {/* <div className="flex flex-wrap gap-2">
          {tags.map((tag) =>
            <Filter key={tag} tag={tag} onClick={() => { filterWorks(tag) }} />
          )}
        </div> */}
        <div className="pt-10 lg:pt-[120px] pb-20 grid grid-cols-1 lg:grid-cols-12 gap-8 gap-y-[60px] lg:gap-y-20">
          <AnimatePresence>
            {data.map((work, index) => (
              <WorkPreview
                section={true}
                key={index}
                media={work.media}
                title={work.title}
                description={work.description}
                link={work.slug}
                tags={work.tags} />
            ))}
          </AnimatePresence>
        </div>
      </div>
    </AnimationPage>
  );
}
