import 'assets/styles/Hamburger.css';
import classNames from 'classnames';

export default function Hamburguer({ onClick, active }) {
  return (
    <button
      type="button"
      className="menu-btn lg:hidden"
      onClick={onClick}
      aria-label={active ? 'Close menu' : 'Open menu'}
    >
      <div className={classNames('hambuguer', { active: active, inactive: !active })}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
  );
}