import { useRef, useState } from 'react';
import { useScroll, useTransform, motion, AnimatePresence } from 'framer-motion';
import animations from 'utils/animations';
import Seo from 'components/Seo';
import AnimationPage from 'components/AnimationPage';
import Slider from 'components/Slider';
import background from 'assets/images/about-reel-bg.png';
import video from 'assets/videos/about-us-not.mp4';
import { Link } from 'react-router-dom';
import Modal from 'components/Modal';


export default function About() {
  const { opacity } = animations.components;
  const [hoveredItem, setHoveredItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    slides,
    capabilities,
    brands,
    team } = require('data/about.json');
  const container = useRef();

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start end', 'end start']
  });

  const translateY = useTransform(
    scrollYProgress,
    [0, 1],
    ['20%', '-20%']
  );

  return (
    <AnimationPage>
      <Seo title="About us - Kollectiff" />
      <div className="container mt-[104px] px-8">
        <motion.h1
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: .2 }}
          className="pt-8 pb-0 lg:py-16">We're a creative engineering company</motion.h1>
      </div>
      <div className="container px-8">
        <motion.div 
          className="pt-10 pb-20 lg:pb-40"
          {...opacity}
          transition={{ delay: .3 }}>
          <Slider slides={slides} />
        </motion.div>
      </div>
      <div className="experiences" ref={container}>
        <div className="absolute z-10 m-auto left-0 right-0 flex flex-col items-center justify-center gap-10 px-8 h-full">
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: .2 }}>
            We create digital experiences
          </motion.h2>
          <motion.button
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: .3 }}
            onClick={() => { setModalOpen(true) }}>
            Check out some of our latest work
          </motion.button>
        </div>
        <motion.img
          src={background}
          alt=""
          className="object-cover w-full h-full"
          style={{ translateY: translateY }} />
      </div>
      <div className="container px-8 py-[120px]">
        <div className="flex flex-col gap-12">
          <motion.h2
            className="text-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: .2 }}>
            Capabilities
          </motion.h2>
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-6 gap-y-[60px] lg:gap-y-0">
            <AnimatePresence mode="wait">
            {capabilities.map((item) => (
              <motion.div
                {...opacity}
                transition={{ delay: 0.3 }}
                className="capability"
                key={item.title}
              >
                <div
                  className="capability-asset"
                  onMouseEnter={() => setHoveredItem(item.title)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  <img
                    src={require(`assets/images/${item.image}`)}
                    alt=""
                    style={{ transition: "opacity 0.3s ease-in-out", zIndex: 0 }}
                  />
                  {hoveredItem === item.title && (
                    <video
                      className="absolute w-full h-full object-cover"
                      src={require(`assets/images/${item.video}`)}
                      autoPlay
                      muted
                      loop
                      style={{ transition: "opacity 0.3s ease-in-out", zIndex: 1 }}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-1.5">
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                </div>
              </motion.div>
            ))}

            </AnimatePresence>
          </div>
        </div>
      </div>
      <div className="we-are-not">
        <video autoPlay playsInline muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div className="container px-8 z-10 py-40 flex flex-col gap-6">
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: .2 }}>
            <span className="text-kollectiff-lime">We’re not an agency, we’re a studio.</span><br />
            We specialize in the ideation and production of innovation-focused<br />
            briefs, products, campaigns, and activations.
          </motion.h2>
          <motion.ul
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: .3 }}>
            <li>You have an idea sold in, we help you <span className="text-kollectiff-lime">BUILD</span></li>
            <li>You have an idea to pitch, we help you <span className="text-kollectiff-lime">SCOPE, PROTOTYPE, BUILD</span></li>
            <li>You are exploring feasibility of an idea in creative technology, we <span className="text-kollectiff-lime">CONSULT</span></li>
          </motion.ul>
        </div>
      </div>
      <div className="container px-8 pt-[120px] pb-20">
        <div className="flex flex-col gap-12">
          <motion.h2
            className="text-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: .2 }}>
            These are some of the brands that trust us
          </motion.h2>
          <div className="brands">
            {brands.map(brand =>
              <motion.img
                key={brand.alt}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: .3 }}
                src={require(`assets/images/${brand.image}`)}
                alt={brand.alt} />
            )}
          </div>
        </div>
      </div>
      {/* <div className="container px-8 py-10">
        <div className="flex flex-col gap-8">
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: .2 }}>
            The great people behind the machine
          </motion.h2>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8 lg:w-10/12">
            {team.map(member =>
              <div className="team" key={member.name}>
                <img src={require(`assets/images/${member.image}`)} alt="" />
                <div>
                  <p className="dark:text-white text-base font-bold">{member.name}</p>
                  <span>{member.position}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div> */}
      <div className="container px-8 pt-0 pb-20 lg:pb-[120px]">
        <motion.h2
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: .2 }}
          className="font-light text-[32px] lg:text-[40px]">
          <Link to="/contact" className="underline">We’re hiring worldwide</Link> -  We’re always happy to hear from talented people who want to work with us, so drop us a line!
        </motion.h2>
      </div>
      <Modal 
        isOpen={modalOpen}
        onClose={() => { setModalOpen(false) }}
        video="https://s3.us-east-1.amazonaws.com/content.kollectiff.com/reels/reel-about-us.mp4"
        videoWidth={1024}
        videoHeight={576} />
    </AnimationPage>
  );
}
