import logo from 'assets/images/hero-logo.svg';
import useVideoStore from 'stores/videoStore';


export default function Hero() {
  const loaded = useVideoStore((state) => state.loaded);

  return (
    <div className="hero relative w-full h-screen overflow-hidden">
      <video
        className="absolute top-1/2 left-1/2 w-auto min-w-full min-h-full transform -translate-x-1/2 -translate-y-1/2 object-cover"
        autoPlay
        playsInline
        muted
        loop
        onLoadedData={loaded}
        id="background-video">
        <source src="https://s3.us-east-1.amazonaws.com/content.kollectiff.com/reels/reel-home.mp4" type="video/mp4" />
        Tu navegador no soporta videos en HTML5.
      </video>
      <div className="container p-8">
        <img src={logo} alt="Kollectiff" />
      </div>
    </div>
  )
}
