import Seo from "components/Seo";
import "assets/styles/PrivacyPolicy.css";
import AnimationPage from "components/AnimationPage";

export default function PrivacyPolicy() {
  return (
    <AnimationPage>
      <Seo title="Privacy Policies - Kollectiff" />
      <div className="container mt-[104px] pb-16 px-8">
        <div className="grid grid-cols-12">
        <div className="col-span-12 lg:col-span-8 privacy-policy">
            <h1 className="py-8 lg:py-16">Privacy Policy</h1>
            <div className="privacy-body">
              <div className="privacy-block">
                <h2>1. Introduction</h2>
                <div className="privacy-content">
                  <div className="privacy-text">
                    <h3 className="text-xl">Who are we?</h3>
                    <p>
                      We are The Motiff Group Inc. (hereinafter “Kollectiff”), a
                      company registered under company number 6020061 and
                      registered office at 131 Continental Dr., Suite 305,
                      Newark, DE 19713. When we decide the means or purpose of
                      processing your personal data, we are the data
                      "controller." We operate the website{" "}
                      <a href="https://kollectiff.com/">
                        https://kollectiff.com/
                      </a>
                      .
                    </p>
                  </div>
                  <div className="privacy-text">
                    <h3 className="text-xl">What’s this policy about?</h3>
                    <p>
                      This policy explains how we process your personal data as
                      a data controller, relating to:
                    </p>
                    <ul>
                      <li>
                        Individuals who visit this website or any of our other
                        websites (“Website Visitors”); and{" "}
                      </li>
                      <li>
                        Suppliers or freelancers who we engage to deliver
                        services to us or on our behalf, or who we communicate
                        with in relation to them prospectively delivering any
                        such services to us or on our behalf (“Suppliers”).
                      </li>
                      <li>
                        People applying for employment with Kollectiff
                        (“Employment Applicants”).
                      </li>
                    </ul>
                    <p>
                      We update this policy from time to time so please check
                      back in.
                    </p>
                  </div>
                  <div className="privacy-text">
                    <h3 className="text-xl">How do you contact us?</h3>
                    <p>
                      If you have questions about this policy or to exercise
                      your rights, write to us at{" "}
                      <a href="mailto:hello@kollectiff.com">
                        hello@kollectiff.com
                      </a>
                      . Please add in the subject ‘Privacy Policy’ so we filter
                      inbound emails appropriately, and connect you with the
                      right team.
                    </p>
                  </div>
                  <div className="privacy-text">
                    <h3 className="text-xl">What are your rights?</h3>
                    <p>
                      You have the following rights, although these rights may
                      be limited in some circumstances:
                    </p>
                    <ul>
                      <li>
                        Ask us to send a copy of data to you or someone else.
                        Ask us to restrict, stop processing, or delete your
                        data.
                      </li>
                      <li>Object to our processing of your data.</li>
                      <li>
                        Object to use of your personal data for direct
                        marketing.
                      </li>
                      <li>Ask us to correct inaccuracies.</li>
                    </ul>
                    <p>
                      If we rely on consent to process data, or send direct
                      marketing, you can withdraw consent by contacting us via
                      the link above.
                    </p>
                  </div>
                </div>
              </div>

              <div className="privacy-block">
                <h2>2. You personal data and how we use it</h2>
                <div className="privacy-content">
                  <p>
                    Where explicit retention periods are not described below, we
                    hold data for as long as necessary bearing in mind the
                    purpose for which it was collected. At the end of the
                    retention period, we assess whether it is necessary to
                    continue to retain Personal Information to achieve the
                    purposes for which it was collected. To determine
                    appropriate retention periods, we consider the amount,
                    nature, and sensitivity of the data, the potential risk of
                    unauthorized access, and legal requirements.
                  </p>
                  <p>
                    Personal Information includes, but is not limited to the
                    following:
                  </p>
                  <div className="privacy-text">
                    <h3 className="text-xl">A. Website Visitors</h3>
                    <ul>
                      <li>
                        We may collect Personal Information from users of the
                        Site to improve website functionality and provide a test
                        environment for our data collection infrastructure. This
                        also enables us to customize experiences and
                        personalization when you are on the Site.
                      </li>
                      <li>
                        We may collect Personal Information to facilitate
                        various webinars and events that we host on topics such
                        as marketing technology and measurement, media, and
                        digital content creation.
                      </li>
                      <li>
                        We may collect Personal Information to offer new digital
                        performance marketing services.
                      </li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <h3 className="text-xl">B. Suppliers</h3>
                    <ul>
                      <li>
                        Contact information such as your first and last name,
                        job title, email address, business address, shipping
                        address, telephone numbers, mobile numbers; and
                      </li>
                      <li>
                        Financial information such as your business credit card
                        or debit card number, bank account information and your
                        payment, service and purchase history.
                      </li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <h3 className="text-xl">C. Employment Applicants</h3>
                    <ul>
                      <li>
                        Contact information such as your first and last name,
                        email address, home or business address, shipping
                        address, telephone numbers, mobile numbers;
                      </li>
                      <li>
                        Your current or past employment or educational
                        information;
                      </li>
                      <li>Relevant licenses and certifications; and/or</li>
                      <li>
                        Other information that could reasonably be used to
                        identify you personally.
                      </li>
                    </ul>
                  </div>
                  <p>
                    Additionally, like most website operators, Kollectiff
                    collects non-personally identifying information of the sort
                    that web browsers and servers typically make available, such
                    as the browser type, language preference, referring site,
                    and the date and time of each Website Visitor request.
                    Kollectiff’s purpose in collecting this non-personally
                    identifying information is to better understand how Website
                    Visitors use the Site.
                  </p>
                  <p>
                    Kollectiff may also collect potentially personally
                    identifying information like Internet Protocol (IP)
                    addresses. Kollectiff only discloses IP addresses under the
                    same circumstances that it discloses personally identifying
                    information as described below.
                  </p>
                  <p>
                    For location information, some of our Services may include
                    features based on an individual’s actual location and may
                    report on an individual’s current location (“Location-Based
                    Services”). To the extent you use any Location-Based
                    Services, we may collect and store information about your
                    location at the time you use those Location-Based Services
                    (in addition to some automatically collected geolocation
                    data). This information can come from a variety of sources
                    depending on the device you use to access the Services; for
                    example, a mobile phone may report its GPS location at the
                    time Location-Based Services are used.
                  </p>
                  <p>
                    If you no longer wish for us to collect and use location
                    information, you may disable the location features on your
                    device. Consult your device manufacturer settings for
                    instructions. Please note that if you disable such features,
                    your ability to access certain features, Services, content,
                    or promotions may be limited or disabled.
                  </p>
                  <p>
                    As mentioned, the Personal Information that Kollectiff
                    collects and stores relating to you is used to communicate
                    to you and to provide our Services to you. Kollectiff takes
                    all measures reasonably necessary to protect against the
                    unauthorized access, use, alteration or destruction of
                    Personal Information.
                  </p>
                  <p>
                    We use your Personal Information for business and commercial
                    purposes, including to use or browse the Site, to offer our
                    Services, to provide you with customer service, to prevent
                    security and fraud, to provide marketing information about
                    our Services, and to perform website and mobile application
                    analytics.
                  </p>
                  <p>
                    We may use the Personal Information we collect about you in
                    a variety of ways, including to:
                  </p>
                  <div className="privacy-text">
                    <h3 className="text-xl">A. To provide our services.</h3>
                    <ul>
                      <li>
                        Contact information such as your first and last name,
                        email address, job title, home or business address,
                        telephone numbers, mobile numbers.
                      </li>
                      <li>Social media account information; and</li>
                      <li>
                        Other information that could reasonably be used to
                        identify you personally.
                      </li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <h3 className="text-xl">B. To communicate with you.</h3>
                    <ul>
                      <li>
                        We process certain Personal Information in order to
                        communicate with you in relation to your accounts, our
                        services, our marketing, and your requests.
                      </li>
                      <li>
                        Our text message program is opt-in and consent to
                        receiving text messages and is not a condition of
                        purchase of our services. We may process your Personal
                        Information to:
                        <ul>
                          <li>
                            communicate with you about our Services, accounts
                            and programs;
                          </li>
                          <li>
                            respond to your customer service inquiries and
                            requests for information;
                          </li>
                          <li>post your comments or statements on the Site;</li>
                          <li>
                            contact you about our Services by any of the methods
                            that you have consented to at the time we collected
                            your Personal Information;
                          </li>
                          <li>
                            communicate with you about our brands, events, or
                            other promotional purposes; and
                          </li>
                          <li>
                            implement your communications preferences, such as
                            sharing Personal Information with our business
                            partners so that they may email you about their
                            promotions, products and initiatives.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <h3 className="text-xl">C. For improvement of the Site.</h3>
                    <p>
                      We want to ensure that the Site is continually improving
                      and expanding so that we meet and exceed your needs and
                      expectations.
                    </p>
                  </div>
                  <div className="privacy-text">
                    <h3 className="text-xl">
                      D. To comply with applicable laws.
                    </h3>
                    <p>
                      We may be required to process certain Personal Information
                      under certain laws and regulations, such as tax laws, as
                      well as to:
                    </p>
                    <ul>
                      <li>
                        maintain appropriate records for internal administrative
                        purposes; and
                      </li>
                      <li>
                        comply with applicable legal and regulatory obligations,
                        and respond to lawful governmental requests, as needed.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="privacy-block">
                <h2>3. Cookies</h2>
                <div className="privacy-content">
                  <p>
                    A cookie is a string of information that a website stores on
                    a visitor’s computer, and that the visitor’s browser
                    provides to the website each time the visitor returns.
                    Kollectiff uses cookies to help us identify and track
                    Website Visitors, their usage of the Site, and their website
                    access preferences. Website Visitors who do not wish to have
                    cookies placed on their computers should set their browsers
                    to refuse cookies before using the Site, with the drawback
                    that certain features of the Site may not function properly
                    without the aid of cookies.
                  </p>
                  <p>
                    The Site may use Strictly Necessary Cookies, Performance
                    Cookies, Functional Cookies, Targeting Cookies and Social
                    Media Cookies. Any of these may be first-party cookies or
                    third-party cookies, persistent or session.
                  </p>
                  <ul>
                    <li>
                      <b>Strictly Necessary Cookies:</b> These are cookies
                      without which you would not be able to use the Site. For
                      example, Strictly Necessary Cookies adjust the Site data
                      transmitted to match your Internet connection, get you to
                      the secure versions of the Site, and help provide services
                      you specifically request. If you set your browser to block
                      these cookies, some parts of the Site will not work.
                      Strictly Necessary Cookies do not store any Personal
                      Information.
                    </li>
                    <li>
                      <b>Performance Cookies: </b> We use these cookies to count
                      visits and traffic sources, to measure and improve Site
                      performance. They help us to know which pages are the most
                      and least popular and see how Site Visitors move around
                      the Site. Performance Cookies do not store any Personal
                      Information. Specifically, for analytics providers, we use
                      Google Analytics. Google Analytics, which is a web
                      analytics service offered by Google tracks and reports
                      website traffic. Google Analytics shares this data with
                      other Google services. Google may use the collected data
                      to contextualize and personalize the ads of its own
                      advertising network. You can opt-out of having made your
                      activity on the Site available to Google Analytics by
                      installing the Google Analytics opt-out browser add-on.
                      The add-on prevents the Google Analytics JavaScript
                      (ga.js, analytics.js, and dc.js) from sharing information
                      with Google Analytics about visits activity. For more
                      information on the privacy practices of Google, please
                      visit the{" "}
                      <a
                        target="_blank"
                        href="https://policies.google.com/privacy?hl=en"
                      >
                        Google Privacy & Terms web page
                      </a>
                      . Google also recommends installing the{" "}
                      <a
                        href="https://tools.google.com/dlpage/gaoptout"
                        target="_blank"
                      >
                        Google Analytics Opt-out Browser Add-on
                      </a>{" "}
                      for your web browser. Google Analytics Opt-out Browser
                      Add-on provides visitors with the ability to prevent their
                      data from being collected and used by Google Analytics.
                      You can also request access, correction, updates or
                      deletion of your Personal Information we collect via
                      MailChimp. If we have collected and process your Personal
                      Information with your consent, then you can withdraw your
                      consent at any time by contacting us or clicking on the
                      “unsubscribe” link located at the bottom of our email
                      communication. Withdrawing your consent will not affect
                      the lawfulness of any processing we conducted prior to
                      your withdrawal, nor will it affect processing of your
                      Personal Information conducted in reliance on lawful
                      processing grounds other than consent. To exercise these
                      rights, please contact us at{" "}
                      <a href="mailto:hello@kollectiff.com">
                        hello@kollectiff.com
                      </a>
                      . For more information on the privacy practices of
                      MailChimp, please visit their{" "}
                      <a href="https://mailchimp.com/legal/" target="_blank">
                        Privacy Policy
                      </a>
                      .
                    </li>
                    <li>
                      <b>Functional cookies:</b> These cookies allow the Site to
                      remember choices you make and provide enhanced
                      functionality and more personalized features. Depending on
                      context, Functional Cookies may store certain types of
                      Personal Information as needed to provide functionality.
                    </li>
                    <li>
                      <b>Targeting Cookies:</b> Targeting cookies help us manage
                      and display our advertisements, based on your activity on
                      the Site and other websites; this is known as
                      interest-based advertising. Targeting cookies mainly rely
                      on uniquely identifying your browser and internet device.
                      To better serve users’ preferences, we have partnered with
                      the{" "}
                      <a href="https://youradchoices.com" target="_blank">
                        Digital Advertising Alliance
                      </a>
                      ; by visiting their website, you can learn more about
                      their members who deliver tailored online ads and your
                      choices to opt-out of receiving them. Opting out of
                      interest-based advertising does not mean you will no
                      longer see advertising online, but it does mean that the
                      companies from which you opt out will no longer show ads
                      that have been tailored to your interests. We work with
                      Google AdSense & DoubleClick Cookie Google, as a
                      third-party vendor, uses cookies to serve ads on the Site.
                      Google’s use of the DoubleClick cookie enables it and its
                      partners to serve ads to our users based on their visit to
                      our Service or other websites on the Internet. You may opt
                      out of the use of the DoubleClick Cookie for
                      interest-based advertising by visiting the{" "}
                      <a
                        href="https://www.google.com/ads/preferences/"
                        target="_blank"
                      >
                        Google Ads Settings web page
                      </a>
                      . Targeting cookies store Personal Information as needed
                      to provide functionality.
                    </li>
                    <li>
                      <b>Google Ads/Analytics:</b> Google Ads remarketing
                      service is provided by Google. You can opt-out of Google
                      Analytics for Display Advertising and customize the Google
                      Display Network ads by visiting the{" "}
                      <a
                        href="https://www.google.com/settings/ads"
                        target="_blank"
                      >
                        Google Ads Settings page
                      </a>
                      . For more information on the privacy practices of Google,
                      please visit the{" "}
                      <a
                        href="https://www.google.com/intl/en/policies/privacy/"
                        target="_blank"
                      >
                        Google Privacy & Terms web page
                      </a>
                      .
                    </li>
                  </ul>
                </div>
              </div>

              <div className="privacy-block">
                <h2>4. Storage, Transfers and Disclosure</h2>
                <div className="privacy-content">
                  <div className="privacy-text">
                    <h3 className="text-xl">Storage & Transfers</h3>
                    <p>
                      Save where indicated otherwise in this policy, your
                      personal data is securely stored in the US. Where we use
                      data processors outside the US, and transfer personal data
                      to a country which does not provide an adequate level of
                      protection, we use contracts which give personal data the
                      same protection it has in the US. For more information
                      please <a href="/contact">contact us</a>.
                    </p>
                  </div>
                  <div className="privacy-text">
                    <h3 className="text-xl">Disclosure</h3>
                    <p>
                      Other than as set out above, we may disclose your personal
                      data:
                    </p>
                    <ul>
                      <li>
                        Where required by law, government, competent authorities
                        or the courts or to establish, exercise or defend our
                        legal rights, and for the purposes of preventing crime
                        and fraud (for example, we may share personal data with
                        our professional advisors, investigators, or credit
                        reference agencies).
                      </li>
                      <li>
                        With members of our corporate group, insurers, advisers,
                        suppliers and subcontractors, as necessary for the
                        purposes set out in this policy.
                      </li>
                      <li>
                        If involved in an investment, merger, acquisition, or
                        sale of our organization or assets, personal data we
                        hold may be shared on the basis of the legitimate
                        interests of us, our shareholders, customers and other
                        parties to a transaction, unless those interests are
                        outweighed by prejudicial impacts upon you.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="privacy-block">
                <h2>5. Legal Basis</h2>
                <div className="privacy-block">
                  <div className="privacy-content">
                    <p>
                      Kollectiff relies on the following lawful grounds to
                      collect and process any personal data you may have
                      provided:
                    </p>
                    <ul>
                      <li>
                        <b>Legitimate Business Interests:</b> Our use of
                        Personal Information relating to your use of the Site
                        and/or the Services, described above, is necessary for
                        our legitimate interests in understanding how the Site
                        and the Services are being used by you, to improve your
                        experience with them, and to contact you with
                        information about our Site and Services. We also have a
                        legitimate interest in aggregating and/or anonymizing
                        the information that we collect through the Site and/or
                        the Services and using this information for our business
                        purposes, as described above. When we process your
                        Personal Information for our legitimate interests, we
                        make sure to consider and balance any potential impact
                        on you, and your rights under data protection laws. Our
                        legitimate business interests do not automatically
                        override your interests – we will not use your Personal
                        Information for activities where our interests are
                        overridden by the impact on you, unless we have your
                        consent or those activities are otherwise required or
                        permitted by law. You have the right to object to
                        processing your Personal Information that is based on
                        our legitimate interests, as further described below.
                      </li>
                      <li>
                        <b>Performance of Contract:</b> Processing of your
                        Personal Information that you provide to us when you
                        interact with our Services is necessary to respond to or
                        implement your request prior to entering into a contract
                        with us. We use account-related data to provide you with
                        access to the Services, contact you regarding your use
                        of the Services or to notify you of important changes to
                        the Services. Such use is also necessary for the
                        performance of the contract between you and us.
                      </li>
                      <li>
                        <b>User Consent:</b> If you are a Website Visitor and
                        have supplied your email address and provided consent,
                        Kollectiff may occasionally send you emails to tell you
                        about industry news and information, solicit your
                        feedback, or keep you up to date with what’s going on
                        with Kollectiff and our services. We primarily use our
                        email newsletter or email you updates on our blog to
                        communicate this type of information. If you do not
                        provide us with your consent to the processing of your
                        Personal Information for this purpose, we will not send
                        you this information. You have the right to withdraw
                        your consent at any time as described below.
                      </li>
                      <li>
                        <b>Employment Application:</b> When you apply for
                        employment through the Site, processing of Personal
                        Information such as your contact details and data about
                        your employment history and education (as needed to
                        evaluate your job application, to conduct job
                        interviews, and as is otherwise needed for recruitment)
                        is necessary to respond to your request to process your
                        application for employment. If you do not provide this
                        Personal Information, we will not be able to process the
                        application that you send through the Site.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="privacy-block">
                <h2>6. Security</h2>
                <div className="privacy-block">
                  <div className="privacy-content">
                    <p>
                      Kollectiff cares about the security of your Personal
                      Information. Although we cannot guarantee 100% security,
                      we use reasonably appropriate security measures to protect
                      your Personal Information against unauthorized use,
                      access, modification, destruction or disclosure. We
                      require our third-party service providers to use similar
                      safeguards to those we use.
                    </p>
                  </div>
                </div>
              </div>
              <div className="privacy-block">
                <h2>7. Sensitive Data</h2>
                <div className="privacy-block">
                  <div className="privacy-content">
                    <p>
                      Kollectiff requests that you do not send us any sensitive
                      data such as social security or national identification
                      numbers, information related to racial or ethnic origin,
                      political opinions, religious beliefs, health data,
                      biometrics or genetics, criminal background or trade union
                      membership information. If you do send Kollectiff this
                      information, then you are consenting to its processing in
                      accordance with this privacy notice. To avoid processing
                      of sensitive data, do not submit it.
                    </p>
                  </div>
                </div>
              </div>
              <div className="privacy-block">
                <h2>8. Our Policy on Children’s Information</h2>
                <div className="privacy-block">
                  <div className="privacy-content">
                    <p>
                      The Site is not directed to children under 16. If we learn
                      that we have received Personal Information from a child
                      who is under 16, we will delete the data. If you learn
                      that your minor child has provided Kollectiff with
                      Personal Information without your consent, please contact
                      us at{" "}
                      <a href="mailto:hello@kollectiff.com">
                        hello@kollectiff.com
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimationPage>
  );
}
