import { useEffect, useState } from 'react';
import { AnimatePresence, motion, wrap } from 'framer-motion';
import arrow from 'assets/images/icon-arrow.svg';
import 'assets/styles/Slider.css';

export default function Slider({ slides }) {
  const [[page, direction], setPage] = useState([0, 0]);
  const index = wrap(0, slides.length, page);

  useEffect(() => {
    const imagePaths = slides.map(slide => slide.image);
    preloadImages(imagePaths);
  }, [slides]);

  const preloadImages = (imageArray) => {
    imageArray.forEach((image) => {
      const img = new Image();
      img.src = require(`assets/images/${image}`);
    });
  };

  const variants = {
    enter: direction => {
      return {
        x: direction > 0 ? 100 : -100,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: direction => {
      return {
        zIndex: 0,
        x: direction < 0 ? 100 : -100,
        opacity: 0
      };
    }
  }

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  }

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-y-10">
          <div className="lg:col-span-6 flex flex-col gap-6 justify-center order-last lg:order-first">
            <motion.h2
              key={page}
              custom={direction}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}>
              {slides[index].text}
            </motion.h2>
            <div className="flex gap-3">
              <motion.button type="button" className="prev" onClick={() => { paginate(-1) }} whileTap={{ scale: 0.8 }} aria-label='Previous'>
              </motion.button>
              <motion.button type="button" className="next" onClick={() => { paginate(1) }} whileTap={{ scale: 0.8 }} aria-label='Next'>
              </motion.button>
            </div>
          </div>
          <div className="lg:col-span-5 lg:col-end-13 overflow-hidden order-first lg:order-last">
            <motion.img
              key={page}
              src={require(`assets/images/${slides[index].image}`)}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              alt=''
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
              }}
            />
          </div>
        </div>
      </AnimatePresence>
    </>
  );
}